<div id="grid-template">
    <ejs-grid #grid
        [height]="settings.height ?? '100%'"
        [columns]="settings.columns ?? undefined" 
        [dataSource]="settings.dataSource"
        [query]="settings.query ?? undefined"
        [allowPaging]="settings.allowPaging ?? true"
        [allowSorting]="settings.allowSorting ?? true"
        [allowFiltering]="settings.allowFiltering ?? true" 
        [allowResizing]="settings.allowResizing ?? true" 
        [allowReordering]="settings.allowReordering ?? true"
        [selectionSettings]="settings.selectionSettings ?? undefined"
        [showColumnChooser]="settings.showColumnChooser ?? true"
        [columnChooserSettings]="settings.columnChooserSettings ?? { operator: 'contains', ignoreAccent: true }"
        [pageSettings]="settings.pageSettings ?? undefined" 
        [filterSettings]="settings.filterSettings ?? { type: 'FilterBar' }"
        [loadingIndicator]="settings.loadingIndicator ?? { indicatorType: 'Shimmer' }" 
        [toolbar]="settings.toolbar ?? undefined" 
        [editSettings]="settings.editSettings ?? { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' }"
        [emptyRecordTemplate]="settings.emptyRecordTemplate ?? undefined"
        [rowRenderingMode]="settings.rowRenderingMode ?? setGridRowRenderingMode()"
        [enableAdaptiveUI]="settings.enableAdaptiveUI ?? setGridAdaptiveUI()"
        [enablePersistence]="false"
        (load)="onGridLoad($event)"
        (created)="onGridCreated($event)"
        (columnDrop)="columnDrop($event)"
        (actionBegin)="onGridActionBegin($event)"
        (actionComplete)="onGridActionComplete($event)"
        (beforeBatchSave)="onGridBatchSave($event)"
        (toolbarClick)="onGridToolbarClick($event)"
        (rowSelected)="onGridRowSelected($event)"
        (rowSelecting)="onGridRowSelecting($event)"
        (rowDeselected)="onGridRowDeselected($event)"
        (rowDeselecting)="onGridRowDeselecting($event)"
        (dataStateChange)="onGridDataStateChange($event)"
        (rowDataBound)="onGridRowDataBound($event)"
        (cellEdit)="onGridCellEdit($event)"
        (cellSave)="onGridCellSave($event)"
        (commandClick)="onCommandClick($event)"
        (queryCellInfo)="onQueryCellInfo($event)"
        (detailDataBound)="onDetailDataBound($event)"
        (dataBound)="onDataBound($event)">

        @if (settings.columns) { 
            <e-columns>
                @for (column of columns; track $index) {
                    <e-column [field]="column.field" [headerText]="column.headerText" [editType]="column.editType"></e-column>
                }
            </e-columns>
        }

        <ng-template #activeDisplayTemplate let-data>  
            <div class="statustxt">
                @if (data.Active === true) { <span class="e-activecolor">Active</span> } 
                @else { <span class="neutral">Inactive</span> }
            </div>
        </ng-template>

        <ng-template #statusTemplate let-data>                       
            <div class="statustxt">
                @if (data.Status === 1 || data.Status === 'Active') { <span class="status-1">Active</span> } 
                @else if (data.Status === 'File Created') { <span class="yes">{{data.Status}}</span> } 
                @else if (data.Status === 'Pending') { <span class="neutral">{{data.Status}}</span> } 
                @else {  <span class="neutral">InActive</span> }
            </div>
        </ng-template>

        <ng-template #yesNoTemplate let-data>                       
            <div class="statustxt">
                @if (data.InTreatment === true) { <span class="icon yes"><fa-icon [icon]="faSquareCheck" /></span> } 
                @else { <span class="icon no"><fa-icon [icon]="faSquareXmark" /></span> }
            </div>
        </ng-template> 

        <ng-template #ratingTemplate let-data>
            <div class="statustxt">
                @if (data.Rating === 'Good Standing') { <span [ngClass]="setRatingClass(data.Rating)">Good</span> } 
                @else if (data.Rating === 'Bad Standing') { <span [ngClass]="setRatingClass(data.Rating)">Bad</span> }
                @else { <span [ngClass]="setRatingClass(data.Rating)">{{data.Rating}}</span> } 
            </div>
        </ng-template>

        <ng-template #fileRatingTemplate let-data>
            <div class="statustxt">
                <span [ngClass]="setRatingClass(data.FileRating)">{{data.FileRating}}</span>
            </div>
        </ng-template>

        <ng-template #websiteLinkTemplate let-data>
            @if (data.Website !== null) { <a href="{{data.Website}}">{{data.Website}}</a> }
        </ng-template>

        <ng-template #isDefaultTemplate let-data>                       
            <div class="statustxt">
                @if (data.IsDefault === 1) { <span class="yes">Yes</span> } 
                @else { <span class="no">No</span> }
            </div>
        </ng-template>

        <ng-template #languageTemplate let-data>
            <div class="custom-template">
                <label class="custom-template-label">Language</label>                
                <ejs-dropdownlist [dataSource]="languages" [value]="data.Language" />
            </div>
        </ng-template>

        <ng-template #notesTemplate let-data>
            <div class="custom-template">
                <label class="custom-template-label">Notes</label>
                <ejs-textarea [value]="data.Notes" />
            </div>
        </ng-template>

        @if (settings.detailDataBound) {
            <ng-template #detailTemplate let-data>
                <div class="custom-details"></div>
            </ng-template>
        }

        <e-aggregates>
            <e-aggregate>
                @if (settings.columns) { 
                    <e-columns>
                        @for (column of columns; track $index) {
                            @if (column.field === 'AmountBilled' || column.field === 'TotalDueProvider' || column.field === 'SettlementValue') {
                                <e-column field="{{column.field}}" type="sum">
                                    <ng-template #footerTemplate let-data>$ {{data.sum}}</ng-template>
                                </e-column>
                            }
                        }
                    </e-columns>
                }
            </e-aggregate>
        </e-aggregates>
    </ejs-grid>

    <ng-content />
    
</div>