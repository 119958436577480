// Angular
import { Component, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

// 3rd Party
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faSquareCheck, faSquareXmark, faPenToSquare, faTrash, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { PageEventArgs, RowDataBoundEventArgs } from '@syncfusion/ej2-grids';
import {
  GridComponent,
  ToolbarItems,
  GridModel,
  ColumnModel,
  EditEventArgs,
  AggregateService,
  GroupService,
  SortService,
  EditService,
  ToolbarService,
  PageService,
  FilterService,
  ColumnChooserService,
  CommandClickEventArgs,
  CommandModel,
  ColumnChooserSettingsModel,
  CommandButtonType } from '@syncfusion/ej2-angular-grids';

// Internal
import { BACKENDURL } from '@environments/environment';
import { languages } from '@models/global-vars';
import { GlobalsService } from '@services/globals/globals.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'grid-template',
  templateUrl: './grid-template.component.html',
  styleUrls: ['./styles/all.scss'],
  providers: [
    AggregateService,
    GroupService,
    SortService,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    ColumnChooserService
  ],
  encapsulation: ViewEncapsulation.None
})
export class GridTemplateComponent {

  constructor(
    public globals: GlobalsService,
    private cognito: CognitoService,
    private toast: ToastMessageService,
    private title: Title,
    private api: ApiService
  ) { }

  // General variables
  isResponsive: boolean = this.globals.isResponsive;
  faSquareCheck: IconDefinition = faSquareCheck;
  faSquareXmark: IconDefinition = faSquareXmark;
  faPenToSquare: IconDefinition = faPenToSquare;
  faTrash: IconDefinition = faTrash;
  faFileLines: IconDefinition = faFileLines;
  languages: string[] = languages;
  customTemplateLabelString: string;
  customTemplateDropdownData: any;
  customTemplateDropdownFields: any;
  customTemplateDropdownValue: any;
  customTemplateCheckboxValue: any;
  contactsDropDownDataSource: any;
  marketManagerDropDownDataSource: any;
  marketManagerDropDownQuery: any;
  caseManagerDropDownDataSource: any;
  caseManagerDropDownQuery: any;
  statusingUserDropDownDataSource: any;
  statusingUserDropDownQuery: any;
  contactsDropDownFields: Object = { id: 'Id', value: 'Id', text: 'ContactName' };

  // Input variables
  @Input() name: string;
  @Input() settings: GridModel;
  @Input() showSave: boolean = false;

  // Grid variables
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('yesNoTemplate', { static: true }) yesNoTemplate!: string;
  @ViewChild('statusTemplate', { static: true }) statusTemplate!: string;
  @ViewChild('activeDisplayTemplate', { static: true }) activeDisplayTemplate!: string;
  @ViewChild('ratingTemplate', { static: true }) ratingTemplate!: string;
  @ViewChild('fileRatingTemplate', { static: true }) fileRatingTemplate!: string;
  @ViewChild('websiteLinkTemplate', { static: true }) websiteLinkTemplate!: string;
  @ViewChild('isDefaultTemplate', { static: true }) isDefaultTemplate!: string;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  @ViewChild('languageTemplate', { static: true }) languageTemplate!: string;
  @ViewChild('notesTemplate', { static: true }) notesTemplate!: string;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate!: string;
  @ViewChild('caseManagerDropDownTemplate', { static: true }) caseManagerDropDownTemplate!: string;
  @ViewChild('marketManagerDropDownTemplate', { static: true }) marketManagerDropDownTemplate!: string;
  @ViewChild('statusingUserDropDownTemplate', { static: true }) statusingUserDropDownTemplate!: string;
  @ViewChild('activeEditTemplate', { static: true }) activeEditTemplate!: string;
  @ViewChild('defaultActionsColumn', { static: true }) defaultActionsColumn!: string;
  @ViewChild('marketManagerDropDown') marketManagerDropDown: DropDownListComponent;
  @ViewChild('statusingUserDropDown') statusingUserDropDown: DropDownListComponent;
  @ViewChild('caseManagerDropDown') caseManagerDropDown: DropDownListComponent;

  columns: ColumnModel[];
  gridParentElement: any;
  columnTemplateClass: string;
  columnTemplateLabel: string;
  columnTemplateContent: string;
  defaultToolbar: ToolbarItems[] = this.isResponsive ? [ 'Add' ] : [ 'Add', 'ColumnChooser' ];
  defaultActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
    { type: 'Delete', title: 'Toggle Active', buttonOption: { iconCss: 'e-icons e-circle-check', cssClass: 'e-flat' } },
    // { type: 'None', title: 'View Log', buttonOption: { iconCss: 'e-icons e-notes', cssClass: 'e-flat' } }, // placeholder for viewing logs
  ];

  ngOnInit(): void {
    // If name for grid defined, add it to the top of the grid
    if (this.name) {
      const toolbar: any[] = this.settings.toolbar ?? this.defaultToolbar;
      this.settings.toolbar = [{ template: `<span class="h2">${this.name}</span>` }].concat(toolbar);
    }

    // Check if Active column exists and add delete command
    if (!this.settings.commandClick && this.columns?.some(col => col.field === 'Active')) {
      const commandsColumn = this.columns.find(col => col.type === 'commands');
      if (commandsColumn) {
        const deleteCommand: CommandModel = { 
          type: 'Delete' as CommandButtonType, 
          title: 'Toggle Active', 
          buttonOption: { 
            iconCss: 'e-icons e-circle-check', 
            cssClass: 'e-flat' 
          } 
        };
        commandsColumn.commands = this.defaultActionsColumnCommands.concat(deleteCommand);
      }
    }
  }

  // Occurs after HTML has been rendered to ensure templates are available
  ngAfterViewInit(): void {
    // this.setGridId();
    this.gridParentElement = this.grid.element.parentElement;

    // Iterate through grid columns and render default templates
    this.grid.columns.forEach((column: ColumnModel) => {

      if (column.type === 'checkbox') {
        column.allowFiltering = false;
        column.textAlign = 'Center';
        column.visible = !this.globals.isResponsive;
      } else if (column.type === 'commands') {
        column.commands = column.commands ?? this.defaultActionsColumnCommands;
      }

      if (column.field === 'Id' || column.field === 'id') {
        column.isPrimaryKey = true;
        column.visible = false;
      } else if (column.field === 'InTreatment') {
        column.template = this.yesNoTemplate;
        column.editType = 'booleanedit';
      } else if (column.field === 'Status') {
        column.template = this.statusTemplate;
      } else if (column.field === 'Active') {
        column.template = this.activeDisplayTemplate;
        column.editType = 'booleanEdit';
      } else if (column.field === 'FileRating') {
        column.template = this.fileRatingTemplate;
      } else if (column.field === 'Rating') {
        column.template = this.ratingTemplate;
      } else if (column.field === 'Website') {
        column.template = this.websiteLinkTemplate;
      } else if (column.field === 'IsDefault') {
        column.template = this.isDefaultTemplate;
      } else if (column.field === 'Language') {
        column.editType = 'dropDownEdit';
      } else if (column.field === 'Minor') {
        column.editType = 'booleanedit';
      } else if (column.field === 'CreatedBy' || column.field === 'UpdatedBy') {
        column.allowEditing = false;
      } else if (column.field === 'CreatedAt' || column.field === 'UpdatedAt') {
        column.type = 'date';
        column.format = 'M/d/y';
        column.allowEditing = false;
      } else if (column.field?.includes('Dob') || column.field?.includes('Date') || column.field === 'FileOpened') {
        column.type = 'date';
        column.editType = 'datepickeredit';
        column.format = 'M/d/y';
      } else if (column.field?.includes('Amount')) {
        column.editType = 'numericEdit';
      } else if (column.field === 'TotalDueProvider' || column.field === 'SettlementValue' || column.field === 'AmountBilled' || column.field === 'AmountPaid' || column.field === 'Amount') {
        column.format = 'C2';
        column.editType = 'numericEdit';
      } 
    });
  }

  setGridId() {
    const grids = document.querySelectorAll('ejs-grid');
    const title = this.name ?? this.title.getTitle().trim().toLowerCase();

    grids.forEach((grid, index) => {
      if (grid.id === this.grid.element.id) {
        this.grid.element.id = `_ejs-component-${title}Grid-${index}`;
      }
    });
  }

  setRatingClass(rating?: string): string | undefined {
    if (rating) {
      if (rating.includes('Good')) {
        return 'good';
      } else if (rating.includes('Bad') || rating.includes('Delinquent')) {
        return 'bad';
      } else if (rating.includes('Warning') || rating.includes('Near') || rating.includes('Caution')) {
        return 'caution';
      } else {
        return 'neutral';
      }
    } else {
      return undefined;
    }
  }

  updateGridLayout() {
    const gridTemplateElement = document.querySelector('grid-template') as HTMLElement;
    
    if (gridTemplateElement) {
      gridTemplateElement.style.display = 'flex';
      gridTemplateElement.style.height = '100%';

      const gridContent = gridTemplateElement.querySelector('.e-grid-content');
      if (gridContent) {
        console.log(gridContent);
      }
    }
    console.log(gridTemplateElement);

    const gridContentElement = document.querySelector('.e-grid-content');
    if (gridContentElement) {
      console.log(gridContentElement);
    }
  }

  // Occurs when data is saved to grid && grid's edit mode is 'Batch'
  onGridBatchSave(args: any): void {

    if (this.settings.beforeBatchSave) {
      this.settings.beforeBatchSave(args);
    } else {
      // Default behavior for saving updated data on the grid
      const comp = this;
      const xhr = new XMLHttpRequest();
      const batchURL = (this.grid.dataSource as DataManager).dataSource.url;
      let data = {
        action: 'batch',
        Changed: args.batchChanges.changedRecords,
        Added: args.batchChanges.addedRecords,
        Deleted: args.batchChanges.deletedRecords,
      };

      // Execute save
      args.cancel = true;
      console.log(JSON.stringify(data));
      xhr.open('POST', batchURL + '/batch');
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.cognito.getUserIdToken());
      xhr.send(JSON.stringify(data));

      // Refresh grid after save
      xhr.onload = function (result) {
        comp.grid?.refresh();
      };
    }
  }

  // Enables pager interactions
  addPagerClasses() {
    if (this.grid?.pageSettings.pageSizes) {
      document.querySelector('.e-adaptive')?.classList.add('e-pager-pagesizes');
    } else {
      document.querySelector('.e-adaptive')?.classList.remove('e-pager-pagesizes');
    }
  }

  onGridCreated(args: any) {
    this.updateGridLayout();
    this.updateSearchBar();
  }

  // Occurs at the start of the grid loading
  onGridLoad(args: any): void {
    
    if (this.settings?.load) {
      this.settings.load(args);
      this.addPagerClasses();
      this.grid.adaptiveDlgTarget = document.querySelector('#grid-template') as HTMLElement;
    } else {
      // Placeholder
      this.addPagerClasses();
      this.grid.adaptiveDlgTarget = document.querySelector('#grid-template') as HTMLElement;
    }
  }

  // Occurs on any beginning action for the grid
  onGridActionBegin(args: PageEventArgs) {
    console.log(args);
    this.updateSearchLogicUsingContains(args);

    if (this.settings?.actionBegin) {
      this.settings.actionBegin(args);
    } else {
      // placeholder
    }
  }

  // Update the condition for filtering to include all options that contain query string
  updateSearchLogicUsingContains(args: any) {
    
    if (args.requestType == "filtering") {
      args.columns.forEach((col: any) => {
        col.operator = 'contains';
      });
    }
  }

  // Occurs on any ending action
  adjustEditDialog(args: EditEventArgs) {

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.width = '90vw';
      // dialog.height = '85vh';
      this.removeLabelClassesFromEditDialogFields(args);
    }
  }

  removeLabelClassesFromEditDialogFields(args: any) {

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = args.dialog;
      const dialogContent = dialog.contentEle;
      
      if (dialogContent) {
        const labels = dialogContent.querySelectorAll('label');

        labels.forEach((labelElement: HTMLElement) => {
          labelElement.classList.remove('e-float-text');
          labelElement.classList.remove('e-label-top');
        })
      }
    }
  }

  // Detects if parent elemnet is responsive and returns string for rendering mode
  setGridRowRenderingMode(): string {

    if (this.gridParentElement && this.gridParentElement.offsetWidth < 425) {
      return 'Vertical';
    } else {
      return 'Horizontal';
    }
  }

  // Detects if parent elemnet is responsive and returns boolean for adaptive UI
  setGridAdaptiveUI(): boolean {

    if (this.gridParentElement && this.gridParentElement.offsetWidth < 425) {
      return true;
    } else {
      return false;
    }
  }

  isGridParentResponsive(): any {
    const parentWidth = this.grid.element.parentElement?.offsetWidth;

    if (parentWidth && parentWidth < 768) {
      this.grid.rowRenderingMode = 'Vertical';
      // this.grid.enableAdaptiveUI = true;
    } else {
      this.grid.rowRenderingMode = 'Horizontal';
      // this.grid.enableAdaptiveUI = false;
    }
  }

  onGridActionComplete(args: any) {
    console.log(args);
    
    if (this.settings?.actionComplete) {
      this.settings.actionComplete(args);
    }

    const gridDatasourceUrl = (this.grid.dataSource as DataManager).dataSource.url ?? 'HEYA';
    const recordId = args.rowData?.Id ?? args.data?.Id; // Assuming 'Id' is the primary key
    const [urlBaseString, urlQueryString] = gridDatasourceUrl?.split('?');
    const patchUrl = `${urlBaseString}/${recordId}`; // Construct the PATCH URL without query parameters

    // Log the URL and the query string
    // console.log('PATCH URL:', patchUrl); // Log the URL without query parameters
    // console.log('Query String:', urlQueryString);
    (this.grid.dataSource as DataManager).dataSource.url = urlBaseString;
    console.log(args);
    if(args.requestType === 'save') {
      console.log(this.marketManagerDropDown);
      console.log(this.caseManagerDropDown);
      console.log(this.statusingUserDropDown);
    }
  }

  // Occurs whenever the buttons above the grid are clicked
  onGridToolbarClick(args: any) {

    if (this.settings?.toolbarClick) {
      this.settings.toolbarClick(args);
    } else {
      // placeholder
    }
  }

  // Occurs whenever a row is clicked
  onGridRowSelected(args: any) {

    if (this.settings?.rowSelected) {
      this.settings.rowSelected(args);
    } else {
      // placeholder
    }
  }

  // Occurs while rows are being selected 
  onGridRowSelecting(args: any) {

    if (this.settings?.rowSelecting) {
      this.settings.rowSelecting(args);

    } else {
      // placeholder
    }
  }
  
  // Occurs after rows deselected
  onGridRowDeselected(args: any) {

    if (this.settings?.rowDeselected) {
      this.settings.rowDeselected(args);

    } else {
      // placeholder
    }
  }

  // Occurs while rows are de-selected
  onGridRowDeselecting(args: any) {

    if (this.settings?.rowDeselecting) {
      this.settings.rowDeselecting(args);
    } else {
      // placeholder
    }
  }

  onGridDataStateChange(args: any) {
    console.log(args);
  }

  // Occurs while rows are de-selected
  onGridRowDataBound(args: RowDataBoundEventArgs) {
    
    if (this.settings?.rowDataBound) {
      this.settings.rowDataBound(args);
    } else {
      // placeholder
    }
  }

  onGridCellEdit(args: any) {

    if (this.settings?.cellEdit) {
      this.settings.cellEdit(args);
    } else {
      // placeholder
    }
  }

  onGridCellSave(args: any) {

    if (this.settings?.cellSave) {
      this.settings.cellSave(args);
    } else {
      // placeholder
    }
  }

  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const column = commandClickArgs.commandColumn;
    const type = column?.type;
    const title = column?.title;

    if (this.settings?.commandClick) {
      this.settings.commandClick(commandClickArgs);
    } else {
      // placeholder
    }

    if (type === 'None' || type === 'Delete') {
      commandClickArgs.cancel = true;

      if (type === 'Delete') {
        this.handleDeleteCommandClick(commandClickArgs.rowData);
      }
    } 
  }

  onQueryCellInfo(args: any) {

    if ((args.column.field === 'Comments' || args.column.field === 'Notes') && args.data[args.column.field]) {
      let tooltip: Tooltip = new Tooltip({
        content: args.data[args.column.field].toString()
      }, args.cell);
    } else if (args.column.field === 'LawFirm.Name') {
      // generate html for tooltip, include law firm state, appreviation, main phone, main fax, special instructions
      const lawFirm = args.data['LawFirm'];
      //const state = lawFirm['State'];
      const abbreviation = lawFirm['Abbreviation'];
      //const mainPhone = lawFirm['MainPhone'];
      //const mainFax = lawFirm['MainFax'];
      const specialInstructions = lawFirm['Notes'] ? lawFirm['Notes'] : 'None';
      let tooltip: Tooltip = new Tooltip({
        content: `<div><b>${lawFirm['Name']}</b></div> Law Firm Abbreviation: (${abbreviation})</div><div>Phone: </div><div>Main Fax: </div><div> Special Instructions: ${specialInstructions}</div>`
      }, args.cell);

    }
  }

  onDetailDataBound(args: any) {
    console.log(args);
    if (this.settings?.detailDataBound) {
      this.settings.detailDataBound(args);
    } else {
      // placeholder
    }
  }

  onDataBound(args: any) {

    if (this.settings?.dataBound) {
      this.settings.dataBound(args);
    } else {
      // placeholder
    }
  }

  // Changes the order of the search bar and header text
  public updateSearchBar() {
    
    let header = document.getElementsByClassName('e-columnheader')[0];
    let filterbar = document.getElementsByClassName('e-filterbar')[0];
    
    if (header != null && filterbar != null) {
      filterbar.insertAdjacentElement("afterend", header);
    }

    let header1 = document.querySelector('e-columnheader');
    let filterbar1 = document.querySelector('e-filterbar');

    if (header1 != null && filterbar1 != null) {
      filterbar.insertAdjacentElement("afterend", header);
    }

    if (filterbar) {
      const inputElements = filterbar.querySelectorAll('input');
      
      if (inputElements) {
        inputElements.forEach((inputElement) => {
          inputElement.placeholder = 'Search';
          inputElement.addEventListener('focus', () => inputElement.placeholder = '');
        })
      }
    }
  }

  // If you have a way to fix pls do but this works for now
  columnDrop(args: any) {
    setTimeout(this.updateSearchBar, 0);
  }

  getSelectedRows = () => this.grid.getSelectedRecords();

  handleEditCommandClick(data: any, event?: Event) {
    this.grid.commandClick.cancel = true;
    const rowIndex = this.grid.getRowIndexByPrimaryKey(data.Id);

    if (rowIndex >= 0) {
      this.grid.selectRow(rowIndex);
      this.grid.startEdit(); 
    }
  }

  handleDeleteCommandClick(data: any, event?: Event) {
    let url = (this.grid.dataSource as DataManager).dataSource.url?.split('?')[0];
  
    if (data) {
      const updatedData = { Active: !data.Active };
      
      if (this.globals.objHasKey(data, 'Active')) {
        if (url && data.Id) {
          url = url.replace(BACKENDURL, '').replace(/\/+/g, '/'); // Normalize multiple slashes
          this.api.fetchRequest(`${url}/${data.Id}`.replace(/\/+/g, '/'), 'PATCH', updatedData).then((res: any) => {
            if (res.ok) this.grid.refreshColumns();
          });
        } else {
          this.toast.showError('No URL or ID found');
        }
      } else {
        this.toast.showError('Record does not have Active property');
      }
    } else {
      this.toast.showError('No data found');
    }
  }

  handleViewLogCommandClick(args: Event, data: any) {
    args.preventDefault();
    console.log(args, data);
  }

  insertToolbarItem(item: string | object): void {

    if (this.grid) {
      
      const toolbar = [...this.grid.toolbar];
      const insertPosition = Math.max(0, toolbar.length - 1);
      toolbar.splice(insertPosition, 0, item);
      this.grid.toolbar = toolbar;
      this.grid.refreshHeader();
    }
  }

  async onMarketManagerDropDownCreated(args: any) {
    this.marketManagerDropDownDataSource = await this.api.getOdata(APIEndpoints.Contacts).executeQuery(new Query().where('ContactTitle', 'contains', 'Market Manager')).then((res) => {
      return (res as any).result;
    });
    // this.marketManagerDropDownQuery = new Query().where('ContactTitle', 'contains', 'Market Manager');
  }

  async onCaseManagerDropDownCreated(args: any) {
    this.caseManagerDropDownDataSource = await this.api.getOdata(APIEndpoints.Contacts).executeQuery(new Query().where('ContactTitle', 'contains', 'Case Manager')).then((res) => {
      return (res as any).result;
    });
    // this.caseManagerDropDownQuery = new Query().where('ContactTitle', 'contains', 'Case Manager');
  }

  async onStatusingUserDropDownCreated(args: any) {
    this.statusingUserDropDownDataSource = await this.api.getOdata(APIEndpoints.Contacts).executeQuery(new Query().where('ContactTitle', 'contains', 'Statusing User')).then((res) => {
      return (res as any).result;
    });
    // this.statusingUserDropDownQuery = new Query().where('ContactTitle', 'contains', 'Statusing User');
  }
}

