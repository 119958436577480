import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridAllModule, AggregateService, GroupService, SortService, EditService, ToolbarService, PageService, FilterService, DetailRowService, ColumnChooserService, ForeignKeyService  } from '@syncfusion/ej2-angular-grids';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { TextAreaAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';

@NgModule({
  imports: [
    CommonModule, 
    GridAllModule,
    FontAwesomeModule,
    DropDownListAllModule,
    TextAreaAllModule,
    TextBoxAllModule,
    CheckBoxAllModule,
  ],
  declarations: [
    GridTemplateComponent
  ],
  providers: [
    AggregateService,
    GroupService,
    SortService,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    DetailRowService,
    ColumnChooserService,
    ForeignKeyService
  ],
  exports: [
    GridTemplateComponent
  ]
})
export class GridTemplateModule {}